import * as remoteData from "../RemoteData";
import { useCommand, useQuery } from "../useAPI";
import * as uKontoApi from "../UKontoFirstPart/UKontoPackagesSelection/api";
import * as cfApi from "../StandardLoan/api";
import { useIsInPersonChannel } from "../useChannel";
import {
  constant,
  constNull,
  constTrue,
  constVoid,
  pipe,
} from "fp-ts/function";
import {
  array,
  boolean,
  eq,
  nonEmptyArray,
  option,
  ord,
  taskEither,
} from "fp-ts";
import {
  GetListError,
  PackageListResponse,
  PackageType,
  UKontoPackage,
} from "./api";
import {
  Body,
  Box,
  Button,
  Card,
  Carousel,
  CheckIcon,
  Children,
  CloseCircleIcon,
  ErrorBanner,
  FeedbackDialog,
  Heading,
  LoadingButton,
  LocalizedString,
  Space,
  Stack,
  TextChildren,
  textChildrenToChildren,
  unsafeLocalizedString,
  useIsMobileLayout,
} from "design-system";
import {
  LocaleKey,
  RuntimeLocaleKey,
  useFormatMessage,
  useFormatRichMessage,
} from "../intl";
import * as classes from "./PackagesSelection.treat";
import cx from "classnames";
import { useEffect, useState } from "react";
import { useAppContext } from "../useAppContext";
import { useBranchExperienceContext } from "../BranchExperience/BranchExperienceContext";
import {
  reducerConfig,
  setPackageAction,
  setSharedAction,
  State,
} from "./state";
import { useShareWithClientButtonProps } from "../Common/ShareWithClientButton/useShareWithClientButtonProps";
import { foldChannelLocation } from "../globalDomain";
import { useParentSharedReducer } from "../BranchExperience/useSharedReducer";
import { FilePDFDialog } from "../Common/Dialogs/FilePDFDialog/FilePDFDialog";
import * as documentAPI from "../Common/documentAPI";
import { InfoTooltip } from "../Common/InfoTooltip/InfoTooltip";
import { usePortalStatusContext } from "../PortalStatusContext";
import { BackButton } from "../Common/BackButton/BackButton";
import { IO } from "fp-ts/IO";
import DebitCardBasic from "./basic.png";
import DebitCardMiddle from "./middle.png";
import DebitCardUpper from "./upper.png";
import { sequenceS } from "fp-ts/Apply";

const foldPackageType: <T>(match: {
  whenBasic: () => T;
  whenMiddle: () => T;
  whenUpper: () => T;
}) => (type: PackageType) => T = match => type => {
  switch (type) {
    case "BASIC":
      return match.whenBasic();
    case "MIDDLE":
      return match.whenMiddle();
    case "UPPER":
      return match.whenUpper();
  }
};

type PackageFeatureVariant = "included" | "not-included";

type PackageFeatureProps = {
  variant: PackageFeatureVariant;
  label: TextChildren;
  info: option.Option<Children>;
};

const PackageFeature = ({ variant, label, info }: PackageFeatureProps) => {
  const foldVariant: <A>(match: {
    whenIncluded: () => A;
    whenNotIncluded: () => A;
  }) => (variant: PackageFeatureVariant) => A = match => variant => {
    switch (variant) {
      case "included":
        return match.whenIncluded();
      case "not-included":
        return match.whenNotIncluded();
    }
  };
  return (
    <Stack grow vAlignContent="top" units={2}>
      {pipe(
        variant,
        foldVariant({
          whenIncluded: () => [
            <CheckIcon
              size="small"
              className={classes.featureIcon["included"]}
            />,
            <span className={classes.featureLabel["included"]}>
              {textChildrenToChildren(label)}
              {pipe(info, option.toNullable)}
            </span>,
          ],
          whenNotIncluded: () => [
            <CloseCircleIcon
              size="small"
              className={classes.featureIcon["not-included"]}
            />,
            <span className={classes.featureLabel["not-included"]}>
              {textChildrenToChildren(label)}
              {pipe(info, option.toNullable)}
            </span>,
          ],
        })
      )}
    </Stack>
  );
};

type PackageFeatureGroupProps = {
  label: TextChildren;
  features: Array<PackageFeatureProps>;
  info: option.Option<Children>;
};

const PackageFeatureGroup = ({
  label,
  features,
  info,
}: PackageFeatureGroupProps) => (
  <Stack column units={2}>
    <Box vAlignContent="center" className={classes.featureGroupContainer}>
      <Body size="small" weight="medium" className={classes.featureGroupLabel}>
        {label}
      </Body>
      {pipe(
        info,
        option.map(info => (
          <>
            <Space units={2} />
            {info}
          </>
        )),
        option.toNullable
      )}
    </Box>
    {pipe(
      features,
      array.mapWithIndex((i, f) => (
        <PackageFeature {...f} key={`package_feature_${i}`} />
      ))
    )}
  </Stack>
);

type PackagePriceProps = {
  amountIntPart: TextChildren;
  amountDecPart?: TextChildren;
  currency: TextChildren;
  info: option.Option<TextChildren>;
  tooltip: option.Option<Children>;
  periodicity: TextChildren;
  before_discount_amount_int_part?: TextChildren;
  before_discount_amount_dec_part?: TextChildren;
  before_discount_currency?: TextChildren;
};

const PackagePrice = ({
  amountIntPart,
  amountDecPart,
  currency,
  info,
  tooltip,
  periodicity,
  before_discount_amount_int_part,
  before_discount_amount_dec_part,
  before_discount_currency,
}: PackagePriceProps) => {
  const orFreeBlock = pipe(
    info,
    option.map(l =>
      l.toString().trim().length > 0 ? (
        <Box vAlignContent="center">
          <Body weight="regular" size="medium">
            {l}
          </Body>
        </Box>
      ) : null
    ),
    option.toNullable
  );

  return (
    <Box column grow vAlignContent="bottom" hAlignContent="center">
      <Stack vAlignContent="bottom" units={2}>
        {before_discount_amount_int_part && (
          <Stack
            vAlignContent="bottom"
            className={classes.priceBeforeDiscountAmount}
          >
            <Box className={classes.priceBeforeDiscountAmountStrike} />
            <Heading
              size="medium"
              weight="medium"
              className={classes.priceBeforeDiscountLabel}
            >
              {before_discount_amount_int_part}
            </Heading>
            {before_discount_amount_dec_part && (
              <Heading
                size="small"
                weight="medium"
                className={classes.priceBeforeDiscountLabel}
              >
                {before_discount_amount_dec_part}
              </Heading>
            )}
            <Space units={2} />
            <Heading
              size="small"
              weight="medium"
              className={classes.priceBeforeDiscountLabel}
            >
              {pipe(
                before_discount_currency,
                option.fromNullable,
                option.fold(
                  () => currency,
                  c => c
                )
              )}
            </Heading>
          </Stack>
        )}

        <Stack style={{ alignItems: "baseline" }}>
          <Heading size="medium" weight="medium">
            {amountIntPart}
          </Heading>
          {amountDecPart && (
            <Heading size="small" weight="medium">
              {amountDecPart}
            </Heading>
          )}
          <Space units={2} />
          <Heading size="small" weight="medium">
            {currency}
          </Heading>
        </Stack>

        {!before_discount_amount_int_part && orFreeBlock}
      </Stack>

      {before_discount_amount_dec_part && orFreeBlock}

      <span className={cx(classes.pricePeriodicity)}>
        {periodicity}
        {pipe(tooltip, option.toNullable)}
      </span>
    </Box>
  );
};

type PackageCardVariant = "default" | "suggested";

type PackageCardProps = {
  variant: PackageCardVariant;
  data: UKontoPackage;
  currentState: State;
  onConfirm: option.Option<
    (ukontoPackage: PackageType) => taskEither.TaskEither<void, void>
  >;
  onChoose: (v: option.Option<PackageType>) => unknown;
  onSharing: (v: boolean) => unknown;
  isCf?: boolean;
  showMastercard: boolean;
};

const PackageCard = ({
  variant,
  data,
  currentState,
  onConfirm,
  onChoose,
  onSharing,
  isCf,
  showMastercard,
}: PackageCardProps) => {
  const formatMessage = useFormatMessage();
  const formatRichMessage = useFormatRichMessage();
  const optionalFormatMessage = (
    id: LocaleKey
  ): option.Option<LocalizedString> =>
    pipe(
      eq.eqString.equals(formatMessage(id), id),
      boolean.fold(
        () => option.some(formatMessage(id)),
        () => option.none
      )
    );
  const optionalRichFormatMessage = (
    id: LocaleKey
  ): option.Option<TextChildren> =>
    pipe(
      eq.eqString.equals(formatMessage(id), id),
      boolean.fold(
        () => option.some(formatRichMessage(id)),
        () => option.none
      )
    );
  const {
    apiParameters: { channel },
    config: {
      packageSelectionOpenMastercardImgUrl,
      packageSelectionMiddleMastercardImgUrl,
      packageSelectionTopMastercardImgUrl,
    },
  } = useAppContext();
  const { branchExperienceFeaturesActive } = useBranchExperienceContext();
  const shouldShare = pipe(
    currentState.sharedWithClient,
    boolean.fold(constTrue, () =>
      pipe(
        currentState.package,
        option.exists(item => item !== data.type)
      )
    )
  );
  const defaultAction = pipe(
    onConfirm,
    option.fold(
      () => taskEither.fromIO<unknown, unknown>(constVoid),
      func => func(data.type)
    )
  );

  const sharedWithClientSubmitButtonProps = useShareWithClientButtonProps({
    disabled: false,
    action: shouldShare
      ? taskEither.fromIO<void, void>(() => onChoose(option.some(data.type)))
      : defaultAction,

    submitLabel: formatMessage("Confirm.buttonLabel"),
    branchExperienceState:
      !branchExperienceFeaturesActive ||
      pipe(
        currentState.package,
        option.exists(item => item === data.type)
      )
        ? "sharedWithClient"
        : "notShared",
    shareWithClientLabel: formatMessage(
      "UKontoFirstPart.UKontoPackagesSelection.action.choose"
    ),
  });

  useEffect(() => {
    onSharing(!sharedWithClientSubmitButtonProps.disabled);
  }, [sharedWithClientSubmitButtonProps.disabled]);

  const regularSubmitButtonProps = {
    action: defaultAction,
    labels: {
      normal: formatMessage(
        "UKontoFirstPart.UKontoPackagesSelection.action.choose"
      ),
      loading: formatMessage(
        "UKontoFirstPart.UKontoPackagesSelection.action.choose"
      ),
      error: formatMessage(
        "UKontoFirstPart.UKontoPackagesSelection.action.choose"
      ),
      success: formatMessage(
        "UKontoFirstPart.UKontoPackagesSelection.action.choose"
      ),
    },
  };

  const submitButtonProps = pipe(
    channel,
    foldChannelLocation({
      Remote: constant(regularSubmitButtonProps),
      InPerson: branchExperienceFeaturesActive
        ? constant(sharedWithClientSubmitButtonProps)
        : constant(regularSubmitButtonProps),
    })
  );

  const isChosen = pipe(
    currentState.package,
    option.fold(
      () => false,
      p =>
        pipe(
          eq.eqString.equals(p, data.type),
          boolean.fold(
            () => false,
            () => true
          )
        )
    )
  );

  const lowerCaseType = data.type.toLowerCase();

  const moreLinkSuffix = ".more_info";
  const tooltipSuffix = ".tooltip";
  const priceAdditionalInfoSuffix = ".additional.info";
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const insuranceInfoTemplate = useCommand(documentAPI.templates);
  const insuranceInfoContent = useCommand(documentAPI.content);
  const { portalBlocked } = usePortalStatusContext();

  return (
    <Box className={classes.cardMaxWidth}>
      {showMoreInfo && (
        <FilePDFDialog
          onDismiss={() => setShowMoreInfo(false)}
          title={formatMessage(
            "UKontoFirstPart.UKontoPackagesSelection.Document.title"
          )}
          file={pipe(
            insuranceInfoTemplate({
              docsets: ["PackageInsuranceInfo"],
            }),
            taskEither.chain(items =>
              pipe(
                insuranceInfoContent({ docId: items[0].docId }),
                taskEither.map(value => ({
                  ...value,
                  downloadUrl: items[0].downloadUrl,
                }))
              )
            )
          )}
        />
      )}
      <Card
        grow
        highlighted={variant === "suggested" || isChosen}
        badge={
          variant === "suggested"
            ? {
                variant: "label",
                label: formatMessage(
                  "UKontoFirstPart.UKontoPackagesSelection.badge"
                ),
              }
            : undefined
        }
      >
        <Box
          shrink
          column
          className={cx(classes.card[variant], isChosen && classes.chosenCard)}
        >
          <Stack column className={classes.header} units={2}>
            <Heading
              size="medium"
              weight="medium"
              align="center"
              className={classes.headerHeading}
            >
              {formatMessage(`package.${lowerCaseType}.name` as LocaleKey)}
            </Heading>
            <Body
              size="x-small"
              weight="medium"
              align="center"
              className={classes.headerBody}
            >
              {formatMessage(
                "UKontoFirstPart.UKontoPackagesSelection.header.subtitle"
              )}
            </Body>

            <Box hAlignContent="center">
              <img
                src={pipe(
                  data.type,
                  foldPackageType({
                    whenBasic: () =>
                      showMastercard
                        ? packageSelectionOpenMastercardImgUrl
                        : DebitCardBasic,
                    whenMiddle: () =>
                      showMastercard
                        ? packageSelectionMiddleMastercardImgUrl
                        : DebitCardMiddle,
                    whenUpper: () =>
                      showMastercard
                        ? packageSelectionTopMastercardImgUrl
                        : DebitCardUpper,
                  })
                )}
                className={
                  showMastercard ? classes.debitCardMc : classes.debitCard
                }
              />
            </Box>
            <Body
              size="x-small"
              weight="medium"
              align="center"
              className={classes.headerBody}
            >
              {formatMessage(
                "UKontoFirstPart.UKontoPackagesSelection.header.caption"
              )}
            </Body>
            <Body
              size="medium"
              weight="regular"
              align="center"
              className={classes.headerCaption}
            >
              {formatMessage(
                `package.${lowerCaseType}.description` as LocaleKey
              )}
            </Body>
          </Stack>

          <Box grow column className={classes.body}>
            <Stack column grow shrink units={6}>
              {pipe(
                Object.keys(data.featuresByGroup),
                array.sort(ord.ordString),
                array.map(k => (
                  <PackageFeatureGroup
                    key={k}
                    label={formatMessage(
                      `package.${lowerCaseType}.${k}` as RuntimeLocaleKey
                    )}
                    features={pipe(
                      data.featuresByGroup[k],
                      array.map(f => ({
                        variant: f.included ? "included" : "not-included",
                        label: formatRichMessage(f.description),
                        info: pipe(
                          optionalFormatMessage(
                            `${f.description}${tooltipSuffix}` as RuntimeLocaleKey
                          ),
                          option.map(msg => (
                            <InfoTooltip content={msg} size="small" />
                          ))
                        ),
                      }))
                    )}
                    info={pipe(
                      optionalFormatMessage(
                        `package.${lowerCaseType}.${k}${moreLinkSuffix}` as RuntimeLocaleKey
                      ),
                      option.map(label => (
                        <Button
                          label={label}
                          variant="text"
                          size="small"
                          action={() => setShowMoreInfo(true)}
                        />
                      ))
                    )}
                  />
                ))
              )}
              <Space fluid />
              <Stack column units={6}>
                {pipe(
                  onConfirm,
                  option.fold(
                    () => <Space units={8} />,
                    () => (
                      <Box
                        shrink
                        basis="0%"
                        className={classes.buttonContainer}
                        hAlignContent="center"
                      >
                        <LoadingButton
                          type="submit"
                          variant={pipe(
                            currentState.package,
                            option.fold(
                              () => "primary",
                              t =>
                                pipe(
                                  eq.eqString.equals(t, data.type),
                                  boolean.fold(
                                    () => "secondary",
                                    () => "primary"
                                  )
                                )
                            )
                          )}
                          size="small"
                          {...submitButtonProps}
                          disabled={
                            (pipe(
                              currentState.package,
                              option.exists(item => item !== data.type)
                            ) &&
                              !currentState.sharedWithClient &&
                              !isCf) ||
                            !!sharedWithClientSubmitButtonProps.disabled ||
                            portalBlocked
                          }
                          className={classes.button}
                        />
                      </Box>
                    )
                  )
                )}

                <PackagePrice
                  amountIntPart={formatMessage(
                    `package.${lowerCaseType}.price.amount_int_part` as LocaleKey
                  )}
                  amountDecPart={pipe(
                    optionalFormatMessage(
                      `package.${lowerCaseType}.price.amount_dec_part` as LocaleKey
                    ),
                    option.toUndefined
                  )}
                  currency={formatMessage(
                    `package.${lowerCaseType}.price.currency` as LocaleKey
                  )}
                  info={pipe(
                    optionalRichFormatMessage(
                      `package.${lowerCaseType}.price${priceAdditionalInfoSuffix}` as LocaleKey
                    )
                  )}
                  tooltip={pipe(
                    optionalFormatMessage(
                      `package.${lowerCaseType}.price.periodicity${tooltipSuffix}` as LocaleKey
                    ),
                    option.map(msg => (
                      <InfoTooltip content={msg} size="small" />
                    ))
                  )}
                  periodicity={formatMessage(
                    `package.${lowerCaseType}.price.periodicity` as LocaleKey
                  )}
                  before_discount_amount_int_part={pipe(
                    optionalFormatMessage(
                      `package.${lowerCaseType}.price.before_discount_amount_int_part` as LocaleKey
                    ),
                    option.map(msg => unsafeLocalizedString(msg.trim())),
                    option.toUndefined
                  )}
                  before_discount_amount_dec_part={pipe(
                    optionalFormatMessage(
                      `package.${lowerCaseType}.price.before_discount_amount_dec_part` as LocaleKey
                    ),
                    option.map(msg => unsafeLocalizedString(msg.trim())),
                    option.toUndefined
                  )}
                  before_discount_currency={pipe(
                    optionalFormatMessage(
                      `package.${lowerCaseType}.price.before_discount_currency` as LocaleKey
                    ),
                    option.getOrElse(() =>
                      formatMessage(
                        `package.${lowerCaseType}.price.currency` as LocaleKey
                      )
                    )
                  )}
                />
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

type PackagesSelectionCommonProps = {
  chosenPackage: option.Option<PackageType>;
  onPackageConfirm: option.Option<
    (packageType: PackageType) => taskEither.TaskEither<void, void>
  >;
  child: boolean;
  isCf?: boolean;
  onBack?: IO<unknown>;
  showMastercard: boolean;
  packageList: PackageListResponse;
};

type PackagesSelectionProps = Omit<
  PackagesSelectionCommonProps,
  "showMastercard" | "packageList"
> &
  (
    | {
        child: true;
        showMastercard: boolean;
        packageList: PackageListResponse;
      }
    | { child: false }
  );

export const PackagesSelection = (props: PackagesSelectionProps) => {
  return props.child ? (
    <PackagesSelectionCommon {...props} />
  ) : (
    <PackagesSelectionParent {...props} />
  );
};

const PackagesSelectionParent = (props: PackagesSelectionProps) => {
  const {
    config: { enableMastercardImpl },
  } = useAppContext();
  const formatMessage = useFormatMessage();

  const [mastercardWhitelist] = useQuery(uKontoApi.getFeatureWhitelist, {
    feature: "enableMastercardImpl",
  });
  const [packages] = useQuery(
    props.isCf ? cfApi.packagesList : uKontoApi.packagesList
  );

  return pipe(
    { mastercardWhitelist, packages },
    sequenceS(remoteData.remoteData),
    remoteData.fold(
      constNull,
      response => {
        if (GetListError.is(response) && "code" in response) {
          return (
            <ErrorBanner>
              {formatMessage(
                ("AccountCollectionByClient." + response.code) as LocaleKey
              )}
            </ErrorBanner>
          );
        } else {
          return <ErrorBanner>{formatMessage("GenericError")}</ErrorBanner>;
        }
      },
      ({ mastercardWhitelist, packages }) => (
        <PackagesSelectionCommon
          {...props}
          showMastercard={mastercardWhitelist || enableMastercardImpl}
          packageList={packages}
        />
      )
    )
  );
};

const PackagesSelectionCommon = (props: PackagesSelectionCommonProps) => {
  const [selectedCarouselIndex, setSelectedCarouselIndex] = useState(1);
  const [selectedPackageType, setSelectedPackageType] = useState<
    option.Option<PackageType>
  >(props.chosenPackage);
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();
  const [
    visibleDialogReservedAccountNumber,
    setVisibleDialogReservedAccountNumber,
  ] = useState(true);
  const [state, dispatch] = useParentSharedReducer(reducerConfig, {
    id: "Initial",
    package: selectedPackageType,
    sharedWithClient: false,
    showMastercard: props.showMastercard,
    packageList: props.packageList,
  });
  const isInPerson = useIsInPersonChannel();

  const cards = (
    packagesData: nonEmptyArray.NonEmptyArray<UKontoPackage>,
    showMastercard: boolean
  ) =>
    pipe(
      packagesData,
      nonEmptyArray.map(data => (
        <PackageCard
          data={data}
          variant={pipe(
            eq.eqString.equals(data.type, "MIDDLE"),
            boolean.fold(
              () => "default",
              () => "suggested"
            )
          )}
          onConfirm={props.onPackageConfirm}
          onChoose={p => dispatch(setPackageAction(p))}
          onSharing={p => dispatch(setSharedAction(p))}
          currentState={
            props.isCf
              ? {
                  id: "Initial",
                  package: selectedPackageType,
                  sharedWithClient: false,
                  showMastercard: showMastercard,
                  packageList: props.packageList,
                }
              : state
          }
          isCf={props.isCf}
          showMastercard={showMastercard}
        />
      ))
    );

  return pipe(props.packageList, packagesData => {
    if (
      option.isNone(selectedPackageType) &&
      props.isCf &&
      packagesData.selectedPackageType !== selectedPackageType
    ) {
      setSelectedPackageType(packagesData.selectedPackageType);
    }
    let account = pipe(
      packagesData.reservedAccountNumber,
      option.fold(
        () => {
          return "";
        },
        accountNo => {
          return accountNo;
        }
      )
    );
    return (
      <Box column grow className={cx(classes.container)}>
        {isInPerson &&
          visibleDialogReservedAccountNumber &&
          !props.child &&
          "" !== account && (
            <FeedbackDialog
              type="warning"
              title={formatMessage(
                "UkontoFirstPart.ReservedAccountNumber.title",
                {
                  account: account,
                }
              )}
              cta={{
                label: formatMessage(
                  "UkontoFirstPart.ReservedAccountNumber.continue"
                ),
                action: () => setVisibleDialogReservedAccountNumber(false),
              }}
            />
          )}
        <Stack column units={2} className={classes.titleContainer}>
          <Box>
            {props.isCf && <Space units={17} />}
            <Heading size="medium" weight="medium">
              {props.isCf
                ? formatMessage(
                    "UKontoFirstPart.UKontoPackagesSelection.subtitle"
                  )
                : formatMessage(
                    "UKontoFirstPart.UKontoPackagesSelection.title"
                  )}
            </Heading>
          </Box>
          {!isMobileLayout && !props.isCf && (
            <Heading size="x-small" weight="regular">
              {formatMessage(
                "UKontoFirstPart.UKontoPackagesSelection.subtitle"
              )}
            </Heading>
          )}
        </Stack>
        <Space units={isMobileLayout ? 4 : 12} />

        {pipe(
          isMobileLayout,
          boolean.fold(
            () => (
              <Stack grow hAlignContent="center" units={8}>
                {cards(packagesData.packagesList, props.showMastercard)}
              </Stack>
            ),
            () => (
              <Carousel
                slides={pipe(
                  cards(packagesData.packagesList, props.showMastercard),
                  array.map(card => (
                    <Box
                      shrink
                      className={classes.cardContainer}
                      hAlignContent="center"
                    >
                      {card}
                    </Box>
                  ))
                )}
                onChange={index => {
                  if (!Number.isNaN(index)) {
                    setSelectedCarouselIndex(index);
                  }
                }}
                value={selectedCarouselIndex}
              />
            )
          )
        )}

        {props.isCf && (
          <Box column>
            <Space units={4} />
            <Box grow column={isMobileLayout}>
              {props.isCf && <Space units={22} />}
              <BackButton action={() => props.onBack && props.onBack()} />
            </Box>
          </Box>
        )}
      </Box>
    );
  });
};
