import "!../../../node_modules/extract-css-chunks-webpack-plugin/dist/loader.js!css-loader?{\"modules\":false,\"url\":false}!./PackagesSelection.treat.2NlwYcD.css";
export var container = 'aHQE3';
export var cardContainer = '_1T-EY';
export var titleContainer = '_3T0Ua';
export var header = 'G-vyT';
export var headerHeading = '_3w36f';
export var headerBody = '_1uYAz';
export var headerCaption = '_3ZIwY';
export var body = '_2nmyf';
export var card = {'default':'_1O38z',suggested:'P8YqH'};
export var cardMaxWidth = '_1lWkr';
export var chosenCard = '_2GuCH';
export var debitCard = '_1XY_5';
export var debitCardMc = 'Gs7QC';
export var buttonContainer = 'q7JXC';
export var button = '_1Ab-w';
export var priceBeforeDiscountAmount = 'y7e77';
export var priceBeforeDiscountLabel = '_3pJ5h';
export var priceBeforeDiscountAmountStrike = '_1l3UM';
export var pricePeriodicity = '_1J6tX';
export var featureIcon = {included:'UegsD','not-included':'UHFyx'};
export var featureLabel = {included:'_1JiI0','not-included':'_3H3ha'};
export var featureGroupLabel = 'jJzTZ';
export var featureGroupContainer = '_2M5tA';
export var priceAdditionalInfo = '_3lN1Z';