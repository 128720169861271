import { useState } from "react";
import { Box, Button, Stack } from "design-system";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { PromoForm } from "../PromoForm";
import { useFormatMessage } from "../../intl";
import { constVoid } from "fp-ts/function";
import { usePortalStatusContext } from "../../PortalStatusContext";

type Props = {
  initialPromoCode: Option<NonEmptyString>;
  onAddedPromoCodeValidated: (promoCode: NonEmptyString) => unknown;
  onSkip: () => unknown;
};

export function BeforeBegin(props: Props) {
  const [hasPromoCode, setHasPromoCode] = useState(false);
  const formatMessage = useFormatMessage();
  const { portalBlocked } = usePortalStatusContext();

  return (
    <Stack column units={6}>
      <PromoForm
        initialPromoCode={props.initialPromoCode}
        onApply={code => {
          setHasPromoCode(true);
          props.onAddedPromoCodeValidated(code);
        }}
        onRemove={() => setHasPromoCode(false)}
        onValidFormChange={constVoid}
        disabled={portalBlocked}
      />
      <Box hAlignContent="right">
        <Button
          variant="primary"
          size="default"
          action={props.onSkip}
          label={formatMessage(
            hasPromoCode
              ? "Identification.MainContent.beforeBegin.confirmCTA"
              : "Identification.MainContent.beforeBegin.skipCTA"
          )}
          disabled={portalBlocked}
        />
      </Box>
    </Stack>
  );
}
