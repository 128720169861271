import * as t from "io-ts";
import { Option } from "fp-ts/Option";
import { option } from "fp-ts";
import { option as optionCodec } from "io-ts-types/lib/option";
import { sharedReducerConfig } from "../BranchExperience/useSharedReducer";
import { PackageListResponse, PackageType } from "./api";

const State = t.type({
  id: t.literal("Initial"),
  package: optionCodec(PackageType),
  packageList: PackageListResponse,
  sharedWithClient: t.boolean,
  showMastercard: t.boolean,
});
export type State = t.TypeOf<typeof State>;

const SetPackageAction = t.type({
  type: t.literal("SetPackage"),
  payload: optionCodec(PackageType),
});

const SetSharedAction = t.type({
  type: t.literal("SetShared"),
  payload: t.boolean,
});

const Action = t.union([SetPackageAction, SetSharedAction]);

export type Action = t.TypeOf<typeof Action>;

export function setSharedAction(payload: boolean): Action {
  return {
    type: "SetShared",
    payload,
  };
}

export function setPackageAction(payload: Option<PackageType>): Action {
  return {
    type: "SetPackage",
    payload,
  };
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SetPackage":
      return {
        id: "Initial",
        package: action.payload,
        packageList: state.packageList,
        sharedWithClient: false,
        showMastercard: state.showMastercard,
      };

    case "SetShared":
      if (state.id === "Initial" && option.isSome(state.package)) {
        return {
          ...state,
          sharedWithClient: action.payload,
        };
      }
      return state;
  }
}

export const reducerConfig = sharedReducerConfig(
  "PackagesSelection",
  State,
  Action,
  reducer
);
