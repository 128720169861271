import { apiCall } from "../APICall";
import * as t from "io-ts";
import { Eq } from "fp-ts/Eq";
import { eq } from "fp-ts";
import { UUID } from "io-ts-types/lib/UUID";
import {
  eqWithCoApplicant,
  withCoApplicant,
  optionFromUndefined,
} from "../globalDomain";
import { NonNegativeInteger, LocalizedString } from "design-system";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { constFalse, constTrue } from "fp-ts/function";
import { EmailVerificationStatus } from "./domain";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

export const OtpGenerationInput = withCoApplicant(
  t.type({
    phoneNumber: t.string,
    recaptchaToken: t.string,
  })
);

export type OtpGenerationInput = t.TypeOf<typeof OtpGenerationInput>;

export const eqOtpGenerationInput: Eq<OtpGenerationInput> = eq.fromEquals(
  constFalse
);

export const OTPGenerationOutput = t.type({
  remainingRequests: NonNegativeInteger,
});

export type NeedCaptchaOutput = t.TypeOf<typeof NeedCaptchaOutput>;

export const NeedCaptchaOutput = t.type({
  needCaptcha: t.boolean,
});

export type OTPGenerationOutput = t.TypeOf<typeof OTPGenerationOutput>;

const InvalidFlowIdError = t.type({
  id: t.literal("InvalidFlowId"),
});

const MaxOtpRequestsReacherError = t.type({
  id: t.literal("MaxOtpRequestsReached"),
});

const MaxPhoneNumberChangesReachedError = t.type({
  id: t.literal("MaxPhoneNumberChangesReached"),
});

const OTPIPBlocked = t.type({
  id: t.literal("OTPIPBlocked"),
});

export const OtpGenerationError = t.union([
  InvalidFlowIdError,
  MaxOtpRequestsReacherError,
  MaxPhoneNumberChangesReachedError,
  OTPIPBlocked,
]);

export type OtpGenerationError = t.TypeOf<typeof OtpGenerationError>;

export const OtpVerifyInput = withCoApplicant(
  t.type({
    otp: t.string,
  })
);

export type OtpVerifyInput = t.TypeOf<typeof OtpVerifyInput>;

const OTPExpiredError = t.type({
  id: t.literal("OTPExpired"),
});

const MaxOtpAttemptsReachedError = t.type({
  id: t.literal("MaxOtpAttemptsReached"),
});

const InvalidOTPError = t.type({
  id: t.literal("InvalidOTP"),
  attemptsLeft: NonNegativeInteger,
});

export const OtpVerifyError = t.union([
  InvalidFlowIdError,
  OTPExpiredError,
  MaxOtpAttemptsReachedError,
  InvalidOTPError,
]);

export type OtpVerifyError = t.TypeOf<typeof OtpVerifyError>;

const StartProcessInput = t.union([
  t.type({
    phoneNumber: optionFromUndefined(t.string),
    processName: t.literal("uKonto"),
  }),
  t.type({
    phoneNumber: t.string,
    processName: t.literal("uKonto"),
  }),
  t.type({
    processName: t.literal("emailVerification"),
  }),
]);

type StartProcessInput = t.TypeOf<typeof StartProcessInput>;

export const startProcess = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["process", "start"],
  inputCodec: StartProcessInput,
});

export const startProcessUkonto = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["packages", "uKonto", "process", "start"],
  inputCodec: StartProcessInput,
});

export const LinkGenerationInput = withCoApplicant(
  t.type({
    email: t.string,
  })
);

const InvalidEmailError = t.type({
  id: t.literal("InvalidEmail"),
});

const MaxLinksAttemptReached = t.type({
  id: t.literal("MaxLinksAttemptsReached"),
});

export const LinkGenerationError = t.union([
  InvalidEmailError,
  MaxLinksAttemptReached,
]);

export type LinkGenerationError = t.TypeOf<typeof LinkGenerationError>;
export type LinkGenerationInput = t.TypeOf<typeof LinkGenerationInput>;

export const eqLinkGenerationInput: Eq<LinkGenerationInput> = eqWithCoApplicant(
  eq.getStructEq({
    email: eq.eqString,
  })
);

export const LinkGenerationOutput = t.type({
  success: t.boolean,
  attemptsLeft: optionFromNullable(NonNegativeInteger),
});
export type LinkGenerationOutput = t.TypeOf<typeof LinkGenerationOutput>;

export const linkGenerationNow = apiCall({
  inputEq: eqLinkGenerationInput,
  path: ["clients", "identification", "email", "activationLink", "now"],
  inputCodec: LinkGenerationInput,
  outputCodec: LinkGenerationOutput,
  errorCodec: LinkGenerationError,
});

export const linkGenerationLater = apiCall({
  inputEq: eqLinkGenerationInput,
  path: ["clients", "identification", "email", "activationLink", "later"],
  inputCodec: LinkGenerationInput,
  outputCodec: LinkGenerationOutput,
  errorCodec: LinkGenerationError,
});

export const linkGenerationStore = apiCall({
  inputEq: eqLinkGenerationInput,
  path: ["clients", "identification", "email", "activationLink", "store"],
  inputCodec: LinkGenerationInput,
  outputCodec: LinkGenerationOutput,
  errorCodec: LinkGenerationError,
});

const EmailActivationLinkInput = t.type({
  uuid: UUID,
});

type EmailActivationLinkInput = t.TypeOf<typeof EmailActivationLinkInput>;

const eqEmailActivationLinkInput: Eq<EmailActivationLinkInput> = eq.getStructEq(
  {
    uuid: eq.eqString,
  }
);
export const emailActivationLinkVerify = apiCall({
  inputEq: eqEmailActivationLinkInput,
  path: ["clients", "identification", "email", "activationLink", "verify"],
  inputCodec: EmailActivationLinkInput,
});

export const EmailVerificationOutput = t.type({
  mailVerificationStatus: EmailVerificationStatus,
});
export type EmailVerificationOutput = t.TypeOf<typeof EmailVerificationOutput>;

export const emailVerification = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "identification", "email", "verificationStatus"],
  inputCodec: withCoApplicant(t.type({})),
  outputCodec: EmailVerificationOutput,
});

const ClientStatus = t.keyof({
  ACTIVE: true,
  POTENTIAL: true,
  CLOSED: true,
  FORMER: true,
  DUMMY: true,
  UNKNOWN: true,
});

type ClientStatus = t.TypeOf<typeof ClientStatus>;

const EmailDuplicityCheckInput = t.type({
  emailAddress: t.string,
});

type EmailDuplicityCheckInput = t.TypeOf<typeof EmailDuplicityCheckInput>;

const DuplicityCheckOutput = t.readonlyArray(
  t.type({
    clientStatus: ClientStatus,
  })
);

export type DuplicityCheckOutput = t.TypeOf<typeof DuplicityCheckOutput>;

const eqEmailDuplicityCheckInput: Eq<EmailDuplicityCheckInput> = eq.getStructEq(
  {
    emailAddress: eq.eqString,
  }
);

export const emailDuplicityCheck = apiCall({
  inputEq: eqEmailDuplicityCheckInput,
  path: ["clients", "contactData", "emailAddressDuplicates", "store"],
  inputCodec: EmailDuplicityCheckInput,
  outputCodec: DuplicityCheckOutput,
});

const PhoneNumberDuplicityCheckInput = t.type({
  mobilePhoneNumber: t.string,
});

type PhoneNumberDuplicityCheckInput = t.TypeOf<
  typeof PhoneNumberDuplicityCheckInput
>;

const eqPhoneNumberDuplicityCheckInput: Eq<PhoneNumberDuplicityCheckInput> = eq.getStructEq(
  {
    mobilePhoneNumber: eq.eqString,
  }
);

export const phoneNumberDuplicityCheck = apiCall({
  inputEq: eqPhoneNumberDuplicityCheckInput,
  path: ["clients", "contactData", "phoneNumberDuplicates", "store"],
  inputCodec: PhoneNumberDuplicityCheckInput,
  outputCodec: DuplicityCheckOutput,
});

export type EmailDuplicityCheckCommand = ReaderTaskEither<
  EmailDuplicityCheckInput,
  unknown,
  DuplicityCheckOutput
>;

export type PhoneNumberDuplicityCheckCommand = ReaderTaskEither<
  PhoneNumberDuplicityCheckInput,
  unknown,
  DuplicityCheckOutput
>;

export const PromoCodeInput = t.type({
  promoCode: t.string,
});
export type PromoCodeInput = t.TypeOf<typeof PromoCodeInput>;
export const eqPromoCodeInput: Eq<PromoCodeInput> = eq.getStructEq({
  promoCode: eq.eqString,
});

const PromoCodeSuccessPayload = t.type({
  result: t.literal("Y"),
  landingPageText: LocalizedString,
  endPageText: LocalizedString,
});
const PromoCodeFailurePayload = t.type({
  result: t.keyof({ X: true, N: true, B: true }),
  landingPageText: t.null,
  endPageText: t.null,
});

const PromoCodeValidateSuccessOutput = t.intersection([
  PromoCodeSuccessPayload,
  t.type({ validationMessage: t.null }),
]);
const PromoCodeValidateFailureOutput = t.intersection([
  PromoCodeFailurePayload,
  t.type({ validationMessage: LocalizedString }),
]);
export const PromoCodeValidateOutput = t.union([
  PromoCodeValidateSuccessOutput,
  PromoCodeValidateFailureOutput,
]);
export type PromoCodeValidateOutput = t.TypeOf<typeof PromoCodeValidateOutput>;

export const PromoCodeReadOutput = t.type({
  ValidPromotionCode: optionFromUndefined(
    t.intersection([
      PromoCodeSuccessPayload,
      t.type({
        promoCode: NonEmptyString,
        isCodeConsumed: t.boolean,
      }),
    ])
  ),
});
export type PromoCodeReadOutput = t.TypeOf<typeof PromoCodeReadOutput>;

export const promoCodeValidate = apiCall({
  inputEq: eqPromoCodeInput,
  path: ["clients", "identification", "promo", "validate"],
  inputCodec: PromoCodeInput,
  outputCodec: PromoCodeValidateOutput,
});

export const promoCodeRemove = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "identification", "promo", "remove"],
  inputCodec: t.void,
});

export const promoCodeRead = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "identification", "promo"],
  inputCodec: t.void,
  outputCodec: PromoCodeReadOutput,
});

const GetClientContactDetailsOutput = t.type({
  phoneNumber: t.string,
});

export const getClientContactDetails = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: [
    "packages",
    "uKonto",
    "identification",
    "potentialClient",
    "getClientContactDetails",
  ],
  inputCodec: t.void,
  outputCodec: GetClientContactDetailsOutput,
});
