import { LocalizedString } from "design-system";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import * as remoteData from "../RemoteData";
import { useAppContext } from "../useAppContext";
import { useQuery } from "../useAPI";
import * as api from "./api";

type Props = {
  children: (promotion: Option<LocalizedString>) => JSX.Element | null;
};

export function WithPromotionBenefit(props: Props) {
  const {
    config: { promotionsEnabled },
  } = useAppContext();

  if (promotionsEnabled) {
    return <FetchPromotionBenefit>{props.children}</FetchPromotionBenefit>;
  } else {
    return props.children(option.none);
  }
}

function FetchPromotionBenefit(props: Props) {
  const [promoCodeQueryResult] = useQuery(api.promoCodeRead);
  const promotion = pipe(
    promoCodeQueryResult,
    remoteData.fold(
      () => option.none,
      () => option.none,
      value => value.ValidPromotionCode
    ),
    option.map(result => result.endPageText)
  );

  return props.children(promotion);
}
