import { option, boolean } from "fp-ts";
import { useFormatMessage } from "../../intl";
import * as t from "io-ts";
import { optionFromUndefined } from "../../globalDomain";
import { sharedReducerConfig } from "../../BranchExperience/useSharedReducer";
import { VerificationCardIssue, NonNegativeInteger } from "design-system";
import { EmailVerificationStatus } from "../domain";
import { Option } from "fp-ts/Option";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { pipe } from "fp-ts/function";
import { useAppContext } from "../../useAppContext";
import { useIsInPersonChannel } from "../../useChannel";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

const EmailVerificationError = t.keyof({
  MaxLinksAttemptsReached: true,
  InvalidEmail: true,
  GenericError: true,
});
export type EmailVerificationError = t.TypeOf<typeof EmailVerificationError>;

export type EmailVerificationType =
  | "SkipVerification"
  | "VerifyNow"
  | "MustConfirmEmail";

export type EmailVerification = {
  type: EmailVerificationType;
  attemptsLeft: Option<NonNegativeInteger>;
};

const EmailVerificationResult = t.keyof({
  Success: true,
  Failure: true,
  VerifyLater: true,
});
export type EmailVerificationResult = t.TypeOf<typeof EmailVerificationResult>;

const ShowEmailStatus = t.type({
  view: t.literal("ShowEmail"),
  initialEmail: t.string,
  error: optionFromUndefined(EmailVerificationError),
});
const InsertEmailStatus = t.type({
  view: t.literal("InsertEmail"),
  initialEmail: optionFromUndefined(t.string),
  error: optionFromUndefined(EmailVerificationError),
});
const DuplicateEmailStatus = t.type({
  view: t.literal("DuplicateEmail"),
  email: t.string,
  error: optionFromUndefined(EmailVerificationError),
});
const WaitingStatus = t.type({
  view: t.literal("Waiting"),
  email: t.string,
  error: optionFromUndefined(EmailVerificationError),
  attemptsLeft: optionFromNullable(NonNegativeInteger),
  hasAlreadyConfirmedEmail: t.boolean,
});
const ResultStatus = t.type({
  view: t.literal("VerificationResult"),
  email: t.string,
  verificationResult: EmailVerificationResult,
  hasAlreadyConfirmedEmail: t.boolean,
});
const ConfirmStatus = t.type({
  view: t.literal("ConfirmPhoneAndEmail"),
  initialEmail: t.string,
  promoCode: optionFromNullable(NonEmptyString),
  error: optionFromUndefined(EmailVerificationError),
});

const State = t.union([
  ShowEmailStatus,
  InsertEmailStatus,
  WaitingStatus,
  DuplicateEmailStatus,
  ResultStatus,
  ConfirmStatus,
]);
type State = t.TypeOf<typeof State>;

const ContinueAfterLinkRequestAction = t.type({
  type: t.literal("ContinueAfterLinkRequest"),
  payload: t.type({
    email: t.string,
    skipVerification: t.boolean,
    attemptsLeft: optionFromNullable(NonNegativeInteger),
  }),
});
const EditEmailAction = t.type({
  type: t.literal("EditEmail"),
});
const SetRequestLinkErrorAction = t.type({
  type: t.literal("SetRequestLinkError"),
  payload: EmailVerificationError,
});
const HandleVerificationAction = t.type({
  type: t.literal("HandleVerification"),
  payload: EmailVerificationResult,
});
const HandleDuplicateEmailAction = t.type({
  type: t.literal("HandleDuplicateEmail"),
  payload: t.type({
    email: t.string,
  }),
});

const ShareWithClientAction = t.type({
  type: t.literal("ShareWithClient"),
  payload: t.type({
    email: t.string,
    promoCode: optionFromNullable(NonEmptyString),
  }),
});
const Action = t.union([
  ContinueAfterLinkRequestAction,
  EditEmailAction,
  SetRequestLinkErrorAction,
  HandleVerificationAction,
  HandleDuplicateEmailAction,
  ShareWithClientAction,
]);

export type Action = t.TypeOf<typeof Action>;

export function editEmailAction(): Action {
  return {
    type: "EditEmail",
  };
}

export function setRequestLinkErrorAction(
  error: EmailVerificationError
): Action {
  return {
    type: "SetRequestLinkError",
    payload: error,
  };
}

export function handleVerificationAction(
  mailVerificationStatus: Exclude<
    EmailVerificationStatus,
    "WaitingForVerification"
  >
): Action {
  return {
    type: "HandleVerification",
    payload: mailVerificationStatus === "Verified" ? "Success" : "Failure",
  };
}
export function continueAfterLinkRequestAction(
  email: string,
  skipVerification: boolean,
  attemptsLeft: Option<NonNegativeInteger>
): Action {
  return {
    type: "ContinueAfterLinkRequest",
    payload: {
      email,
      skipVerification,
      attemptsLeft,
    },
  };
}

export function handleDuplicateEmailAction(email: string): Action {
  return {
    type: "HandleDuplicateEmail",
    payload: {
      email,
    },
  };
}

export function shareWithClientAction(
  email: string,
  promoCode: Option<NonEmptyString>
): Action {
  return {
    type: "ShareWithClient",
    payload: {
      email,
      promoCode,
    },
  };
}

export function useErrorToIssue(): (
  error: EmailVerificationError,
  lastLinkSent?: boolean
) => VerificationCardIssue {
  const formatMessage = useFormatMessage();

  const {
    config: { unicreditBranchesMapURL },
  } = useAppContext();

  const isInPerson = useIsInPersonChannel();

  const navigateToFindBranches = () =>
    (window.location.href = unicreditBranchesMapURL);

  return (error, lastLinkSent) => {
    const content = (() => {
      switch (error) {
        case "InvalidEmail":
          return formatMessage("Identification.email.linkRequest.invalidEmail");
        case "MaxLinksAttemptsReached":
          return formatMessage(
            "Identification.email.linkRequest.maxAttemptsReached"
          );
        case "GenericError":
          return formatMessage("Identification.email.linkRequest.genericError");
      }
    })();

    const getError = (): VerificationCardIssue => ({
      type: "error",
      content,
      title: option.none,
      actions: isInPerson
        ? option.none
        : option.some([
            {
              variant: "secondary",
              action: navigateToFindBranches,
              label: formatMessage(
                "Identification.email.linkRequest.goToBranch"
              ),
            },
          ]),
    });

    switch (error) {
      case "GenericError":
      case "InvalidEmail":
        return getError();
      case "MaxLinksAttemptsReached":
        if (!lastLinkSent) {
          return getError();
        }
        return {
          type: "warning",
          content: formatMessage("Identification.email.warningLastLink"),
          title: option.none,
          actions: option.some([
            {
              label: formatMessage("UKonto.RedirectToTheBank.Button"),
              action: navigateToFindBranches,
              variant: "secondary",
            },
          ]),
        };
    }
  };
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "EditEmail":
      if (state.view === "ConfirmPhoneAndEmail") {
        return {
          view: "InsertEmail",
          initialEmail: option.some(state.initialEmail),
          error: option.none,
        };
      } else if (state.view !== "InsertEmail" && state.view !== "ShowEmail") {
        return {
          view: "InsertEmail",
          initialEmail: option.some(state.email),
          error: option.none,
        };
      } else return state;
    case "ShareWithClient":
      if (state.view === "InsertEmail") {
        return {
          view: "ConfirmPhoneAndEmail",
          initialEmail: action.payload.email,
          promoCode: action.payload.promoCode,
          error: option.none,
        };
      } else return state;
    case "SetRequestLinkError":
      switch (state.view) {
        case "InsertEmail":
          return {
            view: state.view,
            initialEmail: state.initialEmail,
            error: option.some(action.payload),
          };
        case "ShowEmail":
          return {
            view: state.view,
            initialEmail: state.initialEmail,
            error: option.some(action.payload),
          };
        case "ConfirmPhoneAndEmail":
          return {
            view: state.view,
            initialEmail: state.initialEmail,
            promoCode: state.promoCode,
            error: option.some(action.payload),
          };
        case "Waiting":
          return {
            view: state.view,
            email: state.email,
            attemptsLeft: option.none,
            error: option.some(action.payload),
            hasAlreadyConfirmedEmail: state.hasAlreadyConfirmedEmail,
          };
        case "VerificationResult":
        case "DuplicateEmail":
          return state;
      }
    case "HandleVerification":
      if (state.view === "Waiting") {
        return {
          view: "VerificationResult",
          email: state.email,
          verificationResult: action.payload,
          hasAlreadyConfirmedEmail: state.hasAlreadyConfirmedEmail,
        };
      } else return state;
    case "ContinueAfterLinkRequest":
      if (action.payload.skipVerification) {
        return {
          view: "VerificationResult",
          email: action.payload.email,
          verificationResult: "VerifyLater",
          hasAlreadyConfirmedEmail: false,
        };
      } else {
        return {
          view: "Waiting",
          email: action.payload.email,
          attemptsLeft: action.payload.attemptsLeft,
          error: pipe(
            action.payload.attemptsLeft,
            option.fold(
              () => option.none,
              value =>
                pipe(
                  value === 0,
                  boolean.fold(
                    () => option.none,
                    () => option.some("MaxLinksAttemptsReached")
                  )
                )
            )
          ),
          hasAlreadyConfirmedEmail:
            state.view === "Waiting" || state.view === "VerificationResult"
              ? state.hasAlreadyConfirmedEmail
              : state.view === "DuplicateEmail",
        };
      }
    case "HandleDuplicateEmail":
      if (
        state.view === "InsertEmail" ||
        state.view === "ConfirmPhoneAndEmail"
      ) {
        return {
          ...state,
          view: "DuplicateEmail",
          email: action.payload.email,
        };
      } else {
        return state;
      }
  }
}

export const reducerConfig = sharedReducerConfig(
  "EmailVerification",
  State,
  Action,
  reducer
);
