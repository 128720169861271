import * as t from "io-ts";
import { Eq } from "fp-ts/Eq";
import { eq } from "fp-ts";
import {
  NonNegativeInteger,
  unsafePositiveInteger,
  OTP,
  LocalizedString,
} from "design-system";

export const OtpGenerationInput = t.type({
  phoneNumber: t.string,
});

export type OtpGenerationInput = t.TypeOf<typeof OtpGenerationInput>;

export const eqOtpGenerationInput: Eq<OtpGenerationInput> = eq.getStructEq({
  phoneNumber: eq.eqString,
});

export const OtpGenerationOutput = t.type({
  remainingRequests: NonNegativeInteger,
});

export type OtpGenerationOutput = t.TypeOf<typeof OtpGenerationOutput>;

export const InvalidFlowIdError = t.type({
  id: t.literal("InvalidFlowId"),
});

const MaxOtpRequestsReacherError = t.type({
  id: t.literal("MaxOtpRequestsReached"),
});

export const OtpGenerationError = t.union([
  InvalidFlowIdError,
  MaxOtpRequestsReacherError,
]);

export type OtpGenerationError = t.TypeOf<typeof OtpGenerationError>;

export const OtpVerifyInput = t.type({
  // 1 since not really used for encoding in input to API
  otp: OTP(unsafePositiveInteger(1)),
});

export type OtpVerifyInput = t.TypeOf<typeof OtpVerifyInput>;

export const eqOtpVerifyInput: Eq<OtpVerifyInput> = eq.getStructEq({
  otp: eq.eqString,
});

export const OTPExpiredError = t.type({
  id: t.literal("OTPExpired"),
});

export const MaxOtpAttemptsReachedError = t.type({
  id: t.literal("MaxOtpAttemptsReached"),
});

export const InvalidOTPError = t.type({
  id: t.literal("InvalidOTP"),
  attemptsLeft: NonNegativeInteger,
});

export const OtpVerifyError = t.union([
  InvalidFlowIdError,
  OTPExpiredError,
  MaxOtpAttemptsReachedError,
  InvalidOTPError,
]);

export type OtpVerifyError = t.TypeOf<typeof OtpVerifyError>;

export const TransactionIdOutput = t.type({
  transactionId: LocalizedString,
});

export type TransactionIdOutput = t.TypeOf<typeof TransactionIdOutput>;
