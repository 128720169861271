import { TaskEither } from "fp-ts/TaskEither";
import { useFormatMessage } from "../../intl";
import {
  VerificationCard,
  NonNegativeInteger,
  LocalizedString,
} from "design-system";
import { option, taskEither } from "fp-ts";
import { constVoid, pipe } from "fp-ts/function";

import { usePollingEffect } from "../../useAPI";
import * as api from "../api";
import { Option } from "fp-ts/Option";
import { useSnackBar } from "../../useSnackbar";
import { EmailVerificationError, useErrorToIssue } from "./state";
import { EmailVerificationStatus } from "../domain";
import { CoApplicantInput } from "../../globalDomain";
import { APICallImplementation } from "../../APICall";

export type EmailVerificationAPI = APICallImplementation<
  CoApplicantInput,
  unknown,
  api.EmailVerificationOutput
>;

type Props = {
  email: string;
  onRequestLink: TaskEither<unknown, unknown>;
  onReceiveVerification: (
    mailVerificationStatus: Exclude<
      EmailVerificationStatus,
      "WaitingForVerification"
    >
  ) => unknown;
  onEdit: Option<() => unknown>;
  layoutProps:
    | {
        layout: "standalone";
        heading: LocalizedString;
      }
    | { layout: "embedded" };
  error: Option<EmailVerificationError>;
  attemptsLeft: Option<NonNegativeInteger>;
  verificationAPI: EmailVerificationAPI;
} & CoApplicantInput;

export function WaitingVerification(props: Props) {
  const formatMessage = useFormatMessage();
  const errorToIssue = useErrorToIssue();

  usePollingEffect(
    props.verificationAPI,
    {
      intervalMS: 3000,
      shouldPollingContinue: result =>
        result.mailVerificationStatus === "WaitingForVerification",
      onError: constVoid,
      onSuccess: result => {
        if (result.mailVerificationStatus !== "WaitingForVerification") {
          props.onReceiveVerification(result.mailVerificationStatus);
        }
      },
    },
    {
      coApplicant: props.coApplicant,
    }
  );

  const { showSnackbar } = useSnackBar();

  const requestLink = pipe(
    props.onRequestLink,
    taskEither.map(() => {
      showSnackbar({
        type: "success",
        message: formatMessage("Identification.email.newLinkSent"),
        action: option.none,
      });
    })
  );

  return (
    <VerificationCard
      {...props.layoutProps}
      state="waitingForEmailVerification"
      email={props.email}
      emailLabel={formatMessage("Identification.email")}
      editEmailCta={pipe(
        props.onEdit,
        option.map(action => ({
          label: formatMessage("Identification.otp.editContact"),
          action,
        }))
      )}
      verificationHeading={formatMessage("Identification.email.verifyEmail")}
      verificationSubHeading={option.some(
        formatMessage("Identification.email.waitingValidationDescription", {
          email: props.email,
        })
      )}
      loadingMessage={formatMessage("LoadingEllipsis")}
      secondaryAction={{
        cta: {
          action: requestLink,
          label: formatMessage("Identification.email.resendLink"),
        },
        label: formatMessage("Identification.email.nothingInInbox"),
      }}
      instructions={[
        formatMessage("Identification.email.instructionsStep1"),
        formatMessage("Identification.email.instructionsStep2"),
        formatMessage("Identification.email.instructionsStep3"),
      ]}
      issue={pipe(
        props.error,
        option.map(e =>
          errorToIssue(
            e,
            pipe(
              props.attemptsLeft,
              option.exists(value => !value)
            )
          )
        )
      )}
    />
  );
}
