import * as t from "io-ts";
import { sharedReducerConfig } from "../BranchExperience/useSharedReducer";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { optionFromUndefined } from "../globalDomain";
import { option } from "fp-ts";

const InstructionsState = t.type({
  currentStep: t.literal("Instructions"),
  activeIndex: optionFromUndefined(t.number),
});
type InstructionsState = t.TypeOf<typeof InstructionsState>;

const PhoneVerificationState = t.type({
  currentStep: t.literal("PhoneVerification"),
  activeIndex: optionFromUndefined(t.number),
});
type PhoneVerificationState = t.TypeOf<typeof PhoneVerificationState>;

const EmailVerificationState = t.type({
  currentStep: t.literal("EmailVerification"),
  activeIndex: optionFromUndefined(t.number),
});
type EmailVerificationState = t.TypeOf<typeof EmailVerificationState>;

const State = t.union([
  InstructionsState,
  PhoneVerificationState,
  EmailVerificationState,
]);
export type State = t.TypeOf<typeof State>;

const StartVerificationAction = t.type({
  id: t.literal("StartVerification"),
});

const SetActiveItemAction = t.type({
  id: t.literal("SetActiveItem"),
  payload: optionFromUndefined(t.number),
});

const CompletePhoneVerificationAction = t.type({
  id: t.literal("CompletePhoneVerification"),
});

const SetPhoneVerificationAction = t.type({
  id: t.literal("PhoneVerification"),
});

const Action = t.union([
  StartVerificationAction,
  CompletePhoneVerificationAction,
  SetActiveItemAction,
  SetPhoneVerificationAction,
]);
type Action = t.TypeOf<typeof Action>;

export function completePhoneVerificationAction(): Action {
  return {
    id: "CompletePhoneVerification",
  };
}

export function setActiveItemAction(activeItem: Option<number>): Action {
  return {
    id: "SetActiveItem",
    payload: activeItem,
  };
}

export function startVerificationAction(): Action {
  return {
    id: "StartVerification",
  };
}

export function setPhoneVerificationAction(): Action {
  return {
    id: "PhoneVerification",
  };
}

function reducer(state: State, action: Action): State {
  switch (action.id) {
    case "StartVerification":
      if (state.currentStep === "EmailVerification") {
        return {
          ...state,
          activeIndex: pipe(
            state.activeIndex,
            option.map(i => i + 1)
          ),
        };
      }
      return {
        ...state,
        currentStep: "PhoneVerification",
        activeIndex: pipe(
          state.activeIndex,
          option.map(i => i + 1)
        ),
      };
    case "PhoneVerification":
      return {
        ...state,
        currentStep: "PhoneVerification",
      };
    case "CompletePhoneVerification":
      return {
        ...state,
        currentStep: "EmailVerification",
        activeIndex: pipe(
          state.activeIndex,
          option.map(i => i + 1)
        ),
      };
    case "SetActiveItem":
      return { ...state, activeIndex: action.payload };
  }
}

export const reducerConfig = sharedReducerConfig(
  "PhoneAndEmailVerification",
  State,
  Action,
  reducer
);
