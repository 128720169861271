import { Lazy } from "fp-ts/function";
import { LocalizedString } from "design-system";

type State =
  | {
      view: "InputHidden";
    }
  | {
      view: "InsertCode";
    }
  | {
      view: "Verified";
      success: boolean;
      block: boolean;
      message: LocalizedString;
    };

export function foldState<A>(match: {
  whenInputHidden: Lazy<A>;
  whenInsertCode: Lazy<A>;
  whenVerified: (
    success: boolean,
    block: boolean,
    message: LocalizedString
  ) => A;
}): (state: State) => A {
  return state => {
    switch (state.view) {
      case "InputHidden":
        return match.whenInputHidden();

      case "InsertCode":
        return match.whenInsertCode();

      case "Verified":
        return match.whenVerified(state.success, state.block, state.message);
    }
  };
}

type Action = ReturnType<
  | typeof showInputAction
  | typeof handleSuccessAction
  | typeof handleFailureAction
  | typeof handleBlockedAction
  | typeof resetAction
>;

export function showInputAction() {
  return { type: "ShowInput" } as const;
}

export function handleSuccessAction(message: LocalizedString) {
  return { type: "HandleSuccess", message } as const;
}

export function handleFailureAction(message: LocalizedString) {
  return { type: "HandleFailure", message } as const;
}

export function handleBlockedAction(message: LocalizedString) {
  return { type: "HandleBlock", message } as const;
}

export function resetAction() {
  return { type: "Reset" } as const;
}

export function reducer(_: State, action: Action): State {
  switch (action.type) {
    case "ShowInput":
    case "Reset":
      return {
        view: "InsertCode",
      };

    case "HandleSuccess":
    case "HandleFailure":
    case "HandleBlock":
      return {
        view: "Verified",
        success: action.type === "HandleSuccess",
        block: action.type === "HandleBlock",
        message: action.message,
      };
  }
}
