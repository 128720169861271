import * as t from "io-ts";
import { nonEmptyArray } from "io-ts-types/lib/nonEmptyArray";
import { RuntimeLocaleKey } from "../intl";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { GenericError } from "../globalDomain";

export const PackageType = t.keyof({
  BASIC: true,
  MIDDLE: true,
  UPPER: true,
});

export type PackageType = t.TypeOf<typeof PackageType>;

const PackageFeature = t.type(
  {
    description: RuntimeLocaleKey,
    included: t.boolean,
  },
  "PackageFeature"
);

export type PackageFeature = t.TypeOf<typeof PackageFeature>;

const PackageFeaturesList = t.array(PackageFeature, "PackageFeaturesList");
export type PackageFeaturesList = t.TypeOf<typeof PackageFeaturesList>;

const PackageFeaturesByGroup = t.record(t.string, PackageFeaturesList);

export type PackageFeaturesByGroup = t.TypeOf<typeof PackageFeaturesByGroup>;

const UKontoPackage = t.type(
  {
    type: PackageType,
    featuresByGroup: PackageFeaturesByGroup,
  },
  "UKontoPackage"
);

export type UKontoPackage = t.TypeOf<typeof UKontoPackage>;

const PackagesListOutput = nonEmptyArray(UKontoPackage, "PackagesListOutput");

export type PackagesListOutput = t.TypeOf<typeof PackagesListOutput>;

export const PackageListResponse = t.type(
  {
    packagesList: PackagesListOutput,
    reservedAccountNumber: optionFromNullable(t.string),
    selectedPackageType: optionFromNullable(PackageType),
  },
  "PackageListResponse"
);

export type PackageListResponse = t.TypeOf<typeof PackageListResponse>;

export const GetReservedAccountResponse = t.type(
  {
    reservedAccountNumber: optionFromNullable(t.string),
  },
  "GetReservedAccountResponse"
);

export type GetReservedAccountResponse = t.TypeOf<
  typeof GetReservedAccountResponse
>;

export const AccCollectionErrorCode = t.type(
  {
    code: t.string,
  },
  "AccCollectionErrorCode"
);

export const GetListError = t.union([AccCollectionErrorCode, GenericError]);

export const ChoosePackageTypeInput = t.type({
  type: PackageType,
});
