import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LocalizedString } from "design-system";
import { constVoid, pipe } from "fp-ts/function";
import { useCommand, usePollingEffect } from "./useAPI";
import * as api from "./Common/PortalStatusAlert/api";
import { useIdle, useUpdateEffect } from "react-use";
import { option, taskEither } from "fp-ts";
import { useFormatMessage, useFormatPortalStatusDate } from "./intl";
import { sequenceS } from "fp-ts/Apply";

type ProviderProps = {
  children: JSX.Element;
  locale: any;
};

type PortalStatusType = "current" | "scheduled";

export type PortalStatus =
  | { type: "initial" }
  | { type: "none" }
  | { type: PortalStatusType; message: LocalizedString };

export interface PortalStatusContext {
  portalStatus: PortalStatus;
  portalBlocked: boolean;
  setPortalStatus: Dispatch<SetStateAction<PortalStatus>>;
  isAlertModalVisible: boolean;
  setAlertModalVisible: Dispatch<SetStateAction<boolean>>;
  retrieveCurrentPortalStatus: () => unknown;
  unlockPortalStatus: () => unknown;
}

const initialPS: PortalStatus = { type: "initial" };
export const unblockedCurrentPS: PortalStatus = { type: "none" };

export const PortalStatusContext = createContext<PortalStatusContext>({
  portalStatus: initialPS,
  portalBlocked: false,
  setPortalStatus: constVoid,
  unlockPortalStatus: constVoid,
  retrieveCurrentPortalStatus: constVoid,
  isAlertModalVisible: false,
  setAlertModalVisible: constVoid,
});

export const PortalStatusProvider = (props: ProviderProps) => {
  const [portalStatus, setPortalStatus] = useState<PortalStatus>(initialPS);
  const [isAlertModalVisible, setAlertModalVisible] = useState<boolean>(false);
  const formatMessage = useFormatMessage();
  const formatPortalStatusDate = useFormatPortalStatusDate();

  const setScheduledStatus = useCallback(
    (message: LocalizedString) => {
      if (
        portalStatus.type === "initial" ||
        portalStatus.type === "none" ||
        (portalStatus.type === "scheduled" && portalStatus.message !== message)
      ) {
        return setPortalStatus({
          type: "scheduled",
          message,
        });
      }
    },
    [portalStatus]
  );

  const setCurrentStatus = useCallback(
    (message: LocalizedString) => {
      if (
        portalStatus.type !== "current" ||
        (portalStatus.type === "current" && portalStatus.message !== message)
      ) {
        setPortalStatus({
          type: "current",
          message,
        });
        return setAlertModalVisible(true);
      }
    },
    [portalStatus]
  );

  const unlockPortalStatus = () => {
    return setPortalStatus(unblockedCurrentPS);
  };

  const _isUserIdle = useIdle(60_000);
  const isUserIdle = useRef(_isUserIdle);

  const handleCurrentPortalStatusResponse = (resp: api.CurrentPortalStatus) => {
    if (resp.currentlyBlocked) {
      pipe(
        {
          dateTimeFrom: resp.timeFrom,
          dateTimeTo: resp.timeTo,
        },
        sequenceS(option.option),
        option.map(({ dateTimeFrom, dateTimeTo }) =>
          formatMessage("PortalStatus.block.started.message", {
            dateTimeFrom: formatPortalStatusDate(new Date(dateTimeFrom)),
            dateTimeTo: formatPortalStatusDate(new Date(dateTimeTo)),
          })
        ),
        option.getOrElse(() => "" as LocalizedString),
        setCurrentStatus
      );
    } else if (resp.portalBlockScheduled) {
      pipe(
        {
          dateTimeFrom: resp.timeFrom,
          dateTimeTo: resp.timeTo,
        },
        sequenceS(option.option),
        option.map(({ dateTimeFrom, dateTimeTo }) =>
          formatMessage("PortalStatus.block.scheduled.message", {
            dateTimeFrom: formatPortalStatusDate(new Date(dateTimeFrom)),
            dateTimeTo: formatPortalStatusDate(new Date(dateTimeTo)),
          })
        ),
        option.getOrElse(() => "" as LocalizedString),
        setScheduledStatus
      );
    } else {
      unlockPortalStatus();
    }
  };

  const restartCurrentPortalStatus = usePollingEffect(api.currentPortalStatus, {
    intervalMS: 60000, // TODO: make this configurable?
    shouldPollingContinue: () => !isUserIdle.current,
    onError: constVoid,
    onSuccess: handleCurrentPortalStatusResponse,
  });

  const currentPortalStatus = useCommand(api.currentPortalStatus);
  const retrieveCurrentPortalStatus = pipe(
    currentPortalStatus(),
    taskEither.chain(resp =>
      taskEither.fromIO(() => handleCurrentPortalStatusResponse(resp))
    )
  );

  useUpdateEffect(() => {
    restartCurrentPortalStatus();
  }, [props.locale]);

  useEffect(() => {
    isUserIdle.current = _isUserIdle;
    if (!isUserIdle.current) {
      restartCurrentPortalStatus();
    }
  }, [_isUserIdle]);

  const value = useMemo(
    () => ({
      portalStatus,
      portalBlocked: portalStatus.type === "current",
      setPortalStatus,
      isAlertModalVisible,
      setAlertModalVisible,
      unlockPortalStatus,
      retrieveCurrentPortalStatus,
    }),
    [portalStatus, isAlertModalVisible, setCurrentStatus, setScheduledStatus]
  );

  return (
    <PortalStatusContext.Provider value={value}>
      {props.children}
    </PortalStatusContext.Provider>
  );
};

export function usePortalStatusContext() {
  const context = useContext(PortalStatusContext);

  if (!context) {
    throw new Error("PortalStatusContext not provided");
  }

  const setAlertModalVisible = (value: boolean) => {
    if (value !== context.isAlertModalVisible) {
      context.setAlertModalVisible(value);
    }
  };

  return {
    ...context,
    setAlertModalVisible,
  };
}
