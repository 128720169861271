import {
  Box,
  Body,
  Space,
  Button,
  ProfileIcon,
  HomeIcon,
  BadgeIcon,
  AtIcon,
  PaymentIcon,
  PhoneCameraIcon,
  FeatureList,
  Stack,
  Banner,
  UniCreditIconProps,
  LocalizedString,
} from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { Option } from "fp-ts/Option";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { constVoid, pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { PromoForm } from "../PromoForm";
import { useFormatMessage } from "../../intl";
import { foldTenant, Tenant } from "../../globalDomain";
import { useAppContext } from "../../useAppContext";
import { useIsRemoteChannel } from "../../useChannel";

type ListItemProps = {
  icon: (props: UniCreditIconProps) => JSX.Element;
  label: LocalizedString;
};

export function WhatWillNeed(props: {
  initialPromoCode: Option<NonEmptyString>;
  onAccept: () => unknown;
  tenant: Tenant;
}) {
  const formatMessage = useFormatMessage();
  const {
    config: { promotionsEnabled },
  } = useAppContext();

  const isRemote = useIsRemoteChannel();

  const requirementsCZ: NonEmptyArray<ListItemProps> = [
    {
      icon: HomeIcon,
      label: formatMessage(
        "Identification.MainContent.whatWillNeed.CZ.Citizen"
      ),
    },
    {
      icon: ProfileIcon,
      label: formatMessage("Identification.MainContent.whatWillNeed.CZ.Age"),
    },
    {
      icon: BadgeIcon,
      label: formatMessage("Identification.MainContent.whatWillNeed.CZ.IDCard"),
    },
    {
      icon: PaymentIcon,
      label: formatMessage(
        "Identification.MainContent.whatWillNeed.CZ.BankAccount"
      ),
    },
    {
      icon: AtIcon,
      label: formatMessage(
        "Identification.MainContent.whatWillNeed.CZ.MobilePhone"
      ),
    },
  ];

  const requirementsSK: NonEmptyArray<ListItemProps> = [
    {
      icon: HomeIcon,
      label: formatMessage(
        "Identification.MainContent.whatWillNeed.SK.Citizen"
      ),
    },
    {
      icon: ProfileIcon,
      label: formatMessage("Identification.MainContent.whatWillNeed.SK.Age"),
    },
    {
      icon: BadgeIcon,
      label: formatMessage("Identification.MainContent.whatWillNeed.SK.IDCard"),
    },
    {
      icon: AtIcon,
      label: formatMessage(
        "Identification.MainContent.whatWillNeed.SK.MobilePhone"
      ),
    },
    {
      icon: PhoneCameraIcon,
      label: formatMessage(
        "Identification.MainContent.whatWillNeed.SK.PhoneCamera"
      ),
    },
  ];

  return (
    <Box column grow>
      <Stack column units={12}>
        <Body size="medium" weight="regular" color={palette.neutral700}>
          {formatMessage("Identification.MainContent.whatWillNeed.body")}
        </Body>
        {pipe(
          foldTenant(
            props.tenant,
            () => requirementsSK,
            () => requirementsCZ
          ),
          requirements => (
            <FeatureList size="large" children={requirements} />
          )
        )}
        {promotionsEnabled && (
          <PromoForm
            initialPromoCode={props.initialPromoCode}
            onApply={constVoid}
            onRemove={constVoid}
            onValidFormChange={constVoid}
          />
        )}
      </Stack>
      <Space units={6} />
      {isRemote && (
        <Banner
          actions={option.none}
          content={formatMessage(
            "Identification.MainContent.whatWillNeed.newClientsOnlyWarning"
          )}
          title={option.none}
          type="informative"
          onDismiss={option.none}
        />
      )}
      <Space units={6} />
      <Box hAlignContent="right">
        <Button
          variant="primary"
          size="default"
          action={props.onAccept}
          label={formatMessage("Identification.MainContent.whatWillNeed.CTA")}
        />
      </Box>
    </Box>
  );
}
