import { option } from "fp-ts";
import * as t from "io-ts";
import { GenericError, optionFromUndefined } from "../../globalDomain";
import { sharedReducerConfig } from "../../BranchExperience/useSharedReducer";
import * as otpAPI from "../../OTP/domain";
import { NonNegativeInteger } from "design-system";
import { OtpGenerationError } from "../api";

export const OtpError = t.union([
  OtpGenerationError,
  otpAPI.OtpVerifyError,
  GenericError,
]);
export type OtpError = t.TypeOf<typeof OtpError>;

const InsertPhoneStatus = t.type({
  view: t.literal("InsertPhoneNumber"),
  phoneNumber: optionFromUndefined(t.string),
  error: optionFromUndefined(OtpError),
});
const VerifyingOTPStatus = t.type({
  view: t.literal("VerifyingOTP"),
  phoneNumber: t.string,
  loading: t.boolean,
  error: optionFromUndefined(OtpError),
  code: t.string,
  remainingOtpRequests: NonNegativeInteger,
});
const VerifiedStatus = t.type({
  view: t.literal("Verified"),
  phoneNumber: t.string,
});
const State = t.union([InsertPhoneStatus, VerifyingOTPStatus, VerifiedStatus]);
export type State = t.TypeOf<typeof State>;

const SetPhoneNumberAction = t.type({
  type: t.literal("SetPhoneNumber"),
  payload: t.type({
    phoneNumber: t.string,
    remainingOtpAttempts: NonNegativeInteger,
  }),
});
const EditPhoneNumberAction = t.type({
  type: t.literal("EditPhoneNumber"),
});
const CompleteAction = t.type({
  type: t.literal("Complete"),
  payload: t.string,
});
const SetLoadingAction = t.type({
  type: t.literal("SetLoading"),
  payload: t.boolean,
});
const SetOtpRequestErrorAction = t.type({
  type: t.literal("SetOtpRequestError"),
  payload: t.union([OtpGenerationError, GenericError]),
});
const SetOtpVerifyErrorAction = t.type({
  type: t.literal("SetOtpVerifyError"),
  payload: t.union([otpAPI.OtpVerifyError, GenericError]),
});
const SetCodeAction = t.type({
  type: t.literal("SetCode"),
  payload: t.string,
});
const Action = t.union([
  SetPhoneNumberAction,
  EditPhoneNumberAction,
  CompleteAction,
  SetLoadingAction,
  SetOtpRequestErrorAction,
  SetOtpVerifyErrorAction,
  SetCodeAction,
]);
export type Action = t.TypeOf<typeof Action>;

export function setPhoneNumberAction(
  phoneNumber: string,
  remainingOtpAttempts: NonNegativeInteger
): Action {
  return {
    type: "SetPhoneNumber",
    payload: { phoneNumber, remainingOtpAttempts },
  };
}

export function completeAction(phoneNumber: string): Action {
  return {
    type: "Complete",
    payload: phoneNumber,
  };
}

export function editPhoneNumberAction(): Action {
  return {
    type: "EditPhoneNumber",
  };
}

export function setLoadingAction(loading: boolean): Action {
  return {
    type: "SetLoading",
    payload: loading,
  };
}

export function setOtpRequestErrorAction(
  error: OtpGenerationError | GenericError
): Action {
  return { type: "SetOtpRequestError", payload: error };
}

export function setOtpVerifyErrorAction(
  error: otpAPI.OtpVerifyError | GenericError
): Action {
  return { type: "SetOtpVerifyError", payload: error };
}

export function setCodeAction(code: string): Action {
  return { type: "SetCode", payload: code };
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "Complete":
      return {
        view: "Verified",
        phoneNumber: action.payload,
      };
    case "SetPhoneNumber":
      return {
        view: "VerifyingOTP",
        phoneNumber: action.payload.phoneNumber,
        remainingOtpRequests: action.payload.remainingOtpAttempts,
        loading: false,
        error: option.none,
        code: "",
      };
    case "EditPhoneNumber":
      if (state.view === "VerifyingOTP") {
        return {
          view: "InsertPhoneNumber",
          phoneNumber: option.some(state.phoneNumber),
          error: option.none,
        };
      } else return state;
    case "SetLoading":
      if (state.view === "VerifyingOTP") {
        return {
          ...state,
          loading: action.payload,
          error: state.error,
        };
      } else return state;
    case "SetOtpRequestError":
      switch (state.view) {
        case "VerifyingOTP":
          return {
            ...state,
            error: option.some(action.payload),
            code: "",
          };
        case "InsertPhoneNumber":
          return {
            ...state,
            error: option.some(action.payload),
          };
        case "Verified":
          return state;
      }
    case "SetOtpVerifyError":
      switch (state.view) {
        case "VerifyingOTP":
          return {
            ...state,
            error: option.some(action.payload),
            code: "",
          };
        case "InsertPhoneNumber":
          return state;
        case "Verified":
          return state;
      }
    case "SetCode":
      if (state.view === "VerifyingOTP") {
        return {
          ...state,
          error: option.none,
          code: action.payload,
        };
      } else return state;
  }
}

export const reducerConfig = sharedReducerConfig(
  "PhoneVerification",
  State,
  Action,
  reducer
);
