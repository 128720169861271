import { ComponentProps } from "react";
import {
  VerificationCard,
  Banner,
  Stack,
  Button,
  Box,
  LocalizedString,
  useForm,
  validators,
  SnackbarState,
} from "design-system";
import { eq, option, taskEither } from "fp-ts";
import { pipe, flow, constant } from "fp-ts/function";
import { TaskEither } from "fp-ts/TaskEither";
import {
  EmailVerificationError,
  setRequestLinkErrorAction,
  editEmailAction,
  continueAfterLinkRequestAction,
  EmailVerification as EmailVerificationType,
  reducerConfig,
  handleDuplicateEmailAction,
  shareWithClientAction,
  handleVerificationAction,
  useErrorToIssue,
} from "./state";
import { useFormatMessage } from "../../intl";
import { useAppContext } from "../../useAppContext";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { Option } from "fp-ts/Option";
import { useParentSharedReducer } from "../../BranchExperience/useSharedReducer";
import { useBranchExperienceContext } from "../../BranchExperience/BranchExperienceContext";
import { useValidators } from "../../Common/useValidators";
import {
  EmailVerificationAPI,
  WaitingVerification,
} from "./WaitingVerification";
import { useShareWithClientButtonProps } from "../../Common/ShareWithClientButton/useShareWithClientButtonProps";
import {
  CoApplicantInput,
  foldChannelLocation,
  GenericError,
} from "../../globalDomain";
import { useSnackBar } from "../../useSnackbar";
import { PrivacyPolicyDisclaimer } from "../../Common/PrivacyPolicyDisclamer/PrivacyPolicyDisclaimer";
import { useIsRemoteChannel } from "../../useChannel";
import { EmailVerificationStatus } from "../domain";
import { usePropagateHasChanged } from "../../ClientProfile/usePropagateHasChanged";
import { useCommand } from "../../useAPI";
import * as ukontoApis from "../../UKontoSecondPart/api";
import { RestoreApplicationData } from "../../UKontoSecondPart/api";
import { usePortalStatusContext } from "../../PortalStatusContext";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import * as verificationApis from "../api";

type Props = {
  initialEmail: Option<NonEmptyString>;
  initialPromoCode: Option<NonEmptyString>;
  canEdit: boolean;
  onRequestLink: (
    hasAlreadyConfirmedEmail: boolean,
    email: string
  ) => TaskEither<EmailVerificationError, EmailVerificationType>;
  onComplete: () => unknown;
  verificationAPI: EmailVerificationAPI;
  onHasChanged?: (hasChanged: boolean) => unknown;
  saveApplication?: boolean;
  onEmailComplete?: () => unknown;
  restoredData?: RestoreApplicationData;
  skipVerification?: boolean;
} & LayoutProps &
  CoApplicantInput;

export type GenerateLinkNow = ReaderTaskEither<
  verificationApis.LinkGenerationInput,
  verificationApis.LinkGenerationError | GenericError,
  verificationApis.LinkGenerationOutput
>;

type LayoutProps =
  | {
      layout: "embedded";
    }
  | {
      layout: "standalone";
      heading: LocalizedString;
    };

export function EmailVerification(props: Props) {
  const formatMessage = useFormatMessage();
  const saveApplication = useCommand(ukontoApis.saveWithParams);
  const { branchExperienceFeaturesActive } = useBranchExperienceContext();
  const errorToIssue = useErrorToIssue();
  const { portalBlocked } = usePortalStatusContext();

  const {
    apiParameters: { channel },
    config: { externalCommunication },
  } = useAppContext();

  const { showSnackbar } = useSnackBar();
  const snackbarConfig: SnackbarState = {
    type: "success",
    message: formatMessage("Identification.email.verifyEmailSuccess"),
    action: option.none,
  };
  const isRemote = useIsRemoteChannel();

  const [status, dispatch] = useParentSharedReducer(
    reducerConfig,
    props.restoredData &&
      option.isSome(props.restoredData.contactsCollectionData)
      ? props.skipVerification
        ? {
            view: "VerificationResult",
            email: props.restoredData.contactsCollectionData.value.emailAddress,
            verificationResult: "VerifyLater",
            hasAlreadyConfirmedEmail: false,
          }
        : {
            view: "Waiting",
            email: props.restoredData.contactsCollectionData.value.emailAddress,
            attemptsLeft: option.none,
            error: option.none,
            hasAlreadyConfirmedEmail: true,
          }
      : option.isNone(props.initialEmail) || props.canEdit
      ? {
          view: "InsertEmail",
          initialEmail: props.initialEmail,
          error: option.none,
        }
      : {
          view: "ShowEmail",
          initialEmail: props.initialEmail.value,
          error: option.none,
        }
  );
  const continueAfterLinkRequest = (
    verificationType: EmailVerificationType,
    email: string
  ): void => {
    if (verificationType.type === "MustConfirmEmail") {
      dispatch(handleDuplicateEmailAction(email));
    } else if (
      !externalCommunication &&
      verificationType.type === "SkipVerification"
    ) {
      props.onComplete();
    } else {
      if (props.onEmailComplete) {
        props.onEmailComplete();
      }
      props.saveApplication
        ? pipe(
            saveApplication({
              feStep: option.some("CONTACTS_COLLECTION"),
              status: option.some("CREATED"),
              incomeInfo: option.none,
              transactionsInfo: option.none,
            }),
            taskEither.chain(() =>
              taskEither.rightIO(() =>
                dispatch(
                  continueAfterLinkRequestAction(
                    email,
                    verificationType.type === "SkipVerification",
                    verificationType.attemptsLeft
                  )
                )
              )
            )
          )()
        : dispatch(
            continueAfterLinkRequestAction(
              email,
              verificationType.type === "SkipVerification",
              verificationType.attemptsLeft
            )
          );
    }
  };

  const requestLink = (
    hasAlreadyConfirmedEmail: boolean,
    email: string
  ): TaskEither<unknown, void> => {
    return pipe(
      props.onRequestLink(hasAlreadyConfirmedEmail, email),
      taskEither.bimap(
        flow(setRequestLinkErrorAction, dispatch),
        verificationType => continueAfterLinkRequest(verificationType, email)
      )
    );
  };

  const onEdit = pipe(
    flow(editEmailAction, dispatch),
    option.fromPredicate(() => props.canEdit)
  );

  const onShareWithClient = (
    email: string,
    initialPromoCode: Option<NonEmptyString>
  ) => {
    return dispatch(shareWithClientAction(email, initialPromoCode));
  };

  const onSubmit = (confirmed: boolean) => (
    email: string,
    initialPromoCode: Option<NonEmptyString>
  ): TaskEither<EmailVerificationError, unknown> => {
    if (branchExperienceFeaturesActive && !confirmed) {
      return taskEither.fromIO(() =>
        onShareWithClient(email, initialPromoCode)
      );
    } else {
      return pipe(
        props.onRequestLink(false, email),
        taskEither.chain(verificationType =>
          taskEither.fromIO(() =>
            continueAfterLinkRequest(verificationType, email)
          )
        ),
        taskEither.mapLeft(e => {
          dispatch(setRequestLinkErrorAction(e));
          return e;
        })
      );
    }
  };

  const onEmailChange = () => {
    if (
      status.view === "ConfirmPhoneAndEmail" ||
      status.view === "DuplicateEmail"
    ) {
      dispatch(editEmailAction());
    }
  };
  const layoutProps = (() => {
    switch (props.layout) {
      case "standalone":
        return { layout: props.layout, heading: props.heading };
      case "embedded":
        return { layout: props.layout };
    }
  })();

  const { validEmail, nonEmptyString, maxLength } = useValidators();

  const { fieldProps, handleSubmit } = useForm(
    {
      initialValues: {
        email: pipe(props.initialEmail, option.getOrElse(constant(""))),
      },
      fieldValidators: () => ({
        email: validators.inSequence(nonEmptyString, validEmail, maxLength(50)),
      }),
    },
    {
      onSubmit: ({ email }) => {
        pipe(
          props.onHasChanged,
          option.fromNullable,
          option.map(callback => callback(true))
        );
        return onSubmit(status.view === "ConfirmPhoneAndEmail")(
          email,
          props.initialPromoCode
        );
      },
    }
  );

  usePropagateHasChanged<string>({
    enabled: eq.eqString.equals(status.view, "InsertEmail"),
    initialValue: pipe(props.initialEmail, option.getOrElse(constant(""))),
    fieldProps: fieldProps("email"),
    equality: eq.eqString,
    onHasChanged: props.onHasChanged,
  });

  const sharedWithClientSubmitButtonProps = useShareWithClientButtonProps({
    action: handleSubmit,
    submitLabel: externalCommunication
      ? formatMessage("Identification.email.verifyLater")
      : formatMessage("Identification.email.storeEmail"),
    branchExperienceState: (() => {
      switch (status.view) {
        case "DuplicateEmail":
        case "InsertEmail":
          return !branchExperienceFeaturesActive
            ? "sharedWithClient"
            : "notShared";
        case "ConfirmPhoneAndEmail":
          return "sharedWithClient";
        case "Waiting":
        case "VerificationResult":
        case "ShowEmail":
          return "notShared";
      }
    })(),
  });

  const regularSubmitButtonProps = {
    labels: {
      normal: formatMessage("Identification.email.sendEmail"),
      loading: formatMessage("Identification.email.sendEmail"),
      error: formatMessage("Identification.email.sendEmail"),
      success: formatMessage("Identification.email.sendEmail"),
    },
    action: handleSubmit,
  };

  const submitButtonProps = pipe(
    channel,
    foldChannelLocation({
      Remote: constant(regularSubmitButtonProps),
      InPerson: constant(sharedWithClientSubmitButtonProps),
    })
  );

  const verificationCard = (() => {
    switch (status.view) {
      case "ShowEmail":
        return (
          <VerificationCard
            {...layoutProps}
            state="readonlyEmail"
            email={status.initialEmail}
            emailLabel={formatMessage("Identification.email")}
            submitButtonProps={submitButtonProps}
            verificationHeading={formatMessage(
              "Identification.email.verifyEmail"
            )}
            verificationSubHeading={option.some(
              formatMessage("Identification.email.sendEmailDescription")
            )}
            issue={pipe(status.error, option.map(errorToIssue))}
            valueValidationError={fieldProps("email").issues}
          />
        );
      case "DuplicateEmail":
        return (
          <VerificationCard
            {...layoutProps}
            state="insertEmail"
            emailFieldProps={{
              ...fieldProps("email"),
              onChange: value => {
                onEmailChange();
                fieldProps("email").onChange(value);
              },
              label: formatMessage("Identification.email"),
              placeholder: formatMessage(
                "Identification.email.emailPlaceholder"
              ),
            }}
            submitButtonProps={submitButtonProps}
            issue={option.some({
              content: formatMessage(
                "Identification.email.linkRequest.emailHasBeenUsedBefore"
              ),
              title: option.none,
              actions: option.some([
                {
                  variant: "secondary",
                  label: formatMessage("Identification.email.correctEmail"),
                  action: requestLink(true, status.email),
                },
              ]),
              type: "warning",
              onDismiss: option.none,
            })}
          />
        );
      case "InsertEmail":
        return (
          <VerificationCard
            {...layoutProps}
            state="insertEmail"
            emailFieldProps={{
              ...fieldProps("email"),
              onChange: value => {
                onEmailChange();
                fieldProps("email").onChange(value);
              },
              label: formatMessage("Identification.email"),
              placeholder: formatMessage(
                "Identification.email.emailPlaceholder"
              ),
            }}
            submitButtonProps={submitButtonProps}
            issue={pipe(status.error, option.map(errorToIssue))}
            disabled={portalBlocked}
          />
        );
      case "ConfirmPhoneAndEmail":
        return (
          <VerificationCard
            {...layoutProps}
            state="insertEmail"
            emailFieldProps={{
              ...fieldProps("email"),
              onChange: value => {
                onEmailChange();
                fieldProps("email").onChange(value);
              },
              label: formatMessage("Identification.email"),
              placeholder: formatMessage(
                "Identification.email.emailPlaceholder"
              ),
            }}
            submitButtonProps={submitButtonProps}
            issue={pipe(
              status.error,
              option.map(errorToIssue),
              option.altW(() =>
                option.some({
                  type: "informative",
                  title: option.none,
                  actions: option.none,
                  onDismiss: option.none,
                  content: formatMessage(
                    "Identification.email.waitingConfirmation"
                  ),
                } as const)
              )
            )}
            disabled={portalBlocked}
          />
        );
      case "Waiting":
        return (
          <WaitingVerification
            coApplicant={props.coApplicant}
            onRequestLink={requestLink(
              status.hasAlreadyConfirmedEmail,
              status.email
            )}
            onReceiveVerification={(
              mailVerificationStatus: Exclude<
                EmailVerificationStatus,
                "WaitingForVerification"
              >
            ) => {
              if (isRemote && mailVerificationStatus === "Verified") {
                props.onComplete();
                showSnackbar(snackbarConfig);
              }
              dispatch(handleVerificationAction(mailVerificationStatus));
            }}
            email={status.email}
            layoutProps={layoutProps}
            onEdit={onEdit}
            error={status.error}
            attemptsLeft={status.attemptsLeft}
            verificationAPI={props.verificationAPI}
          />
        );
      case "VerificationResult":
        return (
          <Stack column units={8}>
            <VerificationCard
              {...layoutProps}
              state="emailVerificationResult"
              email={status.email}
              emailLabel={formatMessage("Identification.email")}
              editEmailCta={pipe(
                onEdit,
                option.map(action => ({
                  label: formatMessage("Identification.otp.editContact"),
                  action,
                }))
              )}
              type={(() => {
                switch (status.verificationResult) {
                  case "Success":
                  case "VerifyLater":
                    return "success";
                  case "Failure":
                    return "error";
                }
              })()}
              message={(() => {
                switch (status.verificationResult) {
                  case "Success":
                    return formatMessage(
                      "Identification.email.verifyEmailSuccess"
                    );
                  case "VerifyLater":
                    return formatMessage(
                      "Identification.email.verifyLaterEmailSuccess"
                    );
                  case "Failure":
                    return formatMessage("Identification.email.expiredLink");
                }
              })()}
              actions={(() => {
                switch (status.verificationResult) {
                  case "Success":
                  case "VerifyLater":
                    return option.none;
                  case "Failure":
                    return option.some([
                      {
                        variant: "secondary",
                        action: requestLink(
                          status.hasAlreadyConfirmedEmail,
                          status.email
                        ),
                        label: formatMessage(
                          "Identification.email.sendNewEmail"
                        ),
                      },
                    ]) as ComponentProps<typeof Banner>["actions"];
                }
              })()}
            />
            <PrivacyPolicyDisclaimer align="left" />
            <Box hAlignContent="right">
              <Button
                variant="primary"
                size="default"
                label={formatMessage(
                  "Identification.email.emailCompletedButtonLabel"
                )}
                action={props.onComplete}
                disabled={
                  status.verificationResult === "Failure" || portalBlocked
                }
              />
            </Box>
          </Stack>
        );
    }
  })();

  return (
    <Stack units={10} column shrink>
      {verificationCard}
      {status.view !== "VerificationResult" && (
        <PrivacyPolicyDisclaimer align="left" />
      )}
    </Stack>
  );
}
